import {
  Text,
  Skeleton,
  Image,
  DrawerHeader,
  DrawerBody,
  DrawerHeading,
  VStack,
  HStack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Box,
  Link,
  Badge,
  Button,
  Heading,
  Tile,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import {
  CreatorDetailsOverviewDocument,
  ContentLibraryDocument,
  ContentLibraryInput,
} from '../../../generated/backendGraphql'
import { CLOUDINARY_URL } from '../../../config/env'
import { prettyCurrency, prettyFloatingDate } from '@bounty/utils'
import { useAuthState } from '../../../hooks/useAuth'
import b256 from '../../../images/b-256.png'
import { CreatorVideos } from '../components/CreatorVideos'
import { CreatorDetailUserTile } from './CreatorDetailUserTile'
import { useParamsOrThrow } from '@bounty/hooks'
import { BanCreatorButton } from '../../../components/BanCreatorButton'
import { getPreferredSocialProfileBag } from '../../../utils/preferredSocialProfileUtils'
import { FavoriteCreatorButton } from '../../../components/FavoriteCreatorButton/FavoriteCreatorButton'
import { useNavigate } from 'react-router-dom'

export const CreatorDetailsOverview = () => {
  const { shopifyStoreUrl } = useAuthState()
  const navigate = useNavigate()

  const { userId } = useParamsOrThrow(['userId'])

  const { data, loading } = useQueryBackend(CreatorDetailsOverviewDocument, {
    variables: { userId },
  })

  const details = data?.creatorDetails
  const ownedProducts = data?.shopifyStoreProductsCreatorOwns

  const userSocialProfileId = data
    ? getPreferredSocialProfileBag(data?.creatorDetails)!.id
    : null

  const params: ContentLibraryInput = {
    filterByProfileNames: userSocialProfileId ? [userSocialProfileId] : [],
    filterBountyStatus: 'APPROVED',
    filterByCreatedAt: 'ALL_TIME',
    orderBy: 'CREATED_AT',
    orderByDirection: 'DESC',
    skip: 0,
    take: 20,
  }

  const {
    data: contentLibraryData,
    loading: contentLibraryLoading,
    fetchMore,
    error,
  } = useQueryBackend(ContentLibraryDocument, {
    variables: { params },
  })

  const contentLibrary = contentLibraryData?.contentLibrary
  return loading || !details ? (
    <>
      <DrawerHeader>
        <CreatorDetailUserTile />
      </DrawerHeader>
      <DrawerBody>
        <VStack mt={8} spacing={4}>
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
        </VStack>
      </DrawerBody>
    </>
  ) : (
    <>
      <DrawerHeader>
        <DrawerHeading>
          <HStack justifyContent={'space-between'} pr="10">
            <Text>{details.profileName}</Text>
            <FavoriteCreatorButton userId={userId} data={details} />
          </HStack>
        </DrawerHeading>
      </DrawerHeader>
      <DrawerBody display="flex" flexDir={'column'} minHeight={0}>
        <Tabs display="flex" flexDir={'column'} minHeight={0}>
          <TabList>
            <Tab color="gray.600" _active={{ color: 'black' }} px={8}>
              Overview
            </Tab>
            <Tab color="gray.600" _active={{ color: 'black' }} px={8}>
              Products
            </Tab>
            <Tab color="gray.600" _active={{ color: 'black' }} px={8}>
              Bounties
            </Tab>
            <Tab color="gray.600" _active={{ color: 'black' }} px={8}>
              Gift History
            </Tab>
            <Tab color="gray.600" _active={{ color: 'black' }} px={8}>
              Manage
            </Tab>
          </TabList>

          <TabPanels minHeight={0} overflow="auto">
            <TabPanel>
              <VStack width={'100%'} spacing={'8'} mb="8">
                <Button
                  event="Creator Detail Drawer Offer Bounty Clicked"
                  onClick={() => {
                    navigate('./give-gift')
                  }}
                  width="100%"
                  isDisabled={data.creatorDetails.creatorLimitForStore === 0}
                >
                  Offer Gift
                </Button>
                <CreatorDetailUserTile />
                <Tile width="100%" backgroundColor={'neutral.50'}>
                  <HStack py={6} justifyContent="space-around">
                    <VStack>
                      <Text fontSize="lg">Bounties</Text>
                      <Text fontSize="lg" fontWeight="semibold">
                        {details.numBounties}
                      </Text>
                    </VStack>
                    <VStack>
                      <Text fontSize="lg">Total network earning</Text>
                      <Text fontSize="lg" fontWeight="semibold">
                        {prettyCurrency(details.totalEarned, {
                          precision: 2,
                        })}
                      </Text>
                    </VStack>
                    <VStack>
                      <Text fontSize="lg">Joined On</Text>
                      <Text fontSize="lg" fontWeight="semibold">
                        {new Date(details.firstActiveDate).toLocaleDateString()}
                      </Text>
                    </VStack>
                  </HStack>
                </Tile>
              </VStack>
              <CreatorVideos
                items={contentLibrary?.items}
                loading={contentLibraryLoading}
                error={!!error}
                hasNextPage={
                  !contentLibrary ||
                  contentLibrary.items.length < contentLibrary.count
                }
                onLoadMore={() => {
                  fetchMore({
                    variables: {
                      params: {
                        ...params,
                        skip:
                          contentLibraryData?.contentLibrary.items.length ?? 0,
                      },
                    },
                  })
                }}
              />
            </TabPanel>

            <TabPanel>
              <VStack mt={6} spacing={4} alignItems="flex-start">
                {ownedProducts?.map((p) => {
                  // first order that contains the productId so we show associated order
                  const order = details.orders?.find((o) =>
                    o.shopifyProductIds.includes(p.productId),
                  )
                  const orderId = order?.shopifyOrderId
                  const orderNumber = order?.shopifyOrderNumber
                  const orderedAt = new Date(
                    order?.createdAt,
                  ).toLocaleDateString()
                  return (
                    <HStack key={p.id} spacing={4}>
                      <Image
                        height="75px"
                        width="75px"
                        loading="lazy"
                        objectFit="contain"
                        src={p.imgLink ?? b256}
                        alt={p.productName}
                        borderRadius="lg"
                      />
                      <VStack alignItems="flex-start">
                        <Text fontSize="xl" fontWeight="semibold">
                          {p.productName}
                        </Text>
                        <HStack>
                          <Link
                            color="gray.600"
                            isExternal
                            href={`https://${shopifyStoreUrl}/admin/orders/${orderId}`}
                            pr={3}
                            borderRight="1px solid"
                            borderColor="gray.400"
                          >
                            #{orderNumber}
                          </Link>
                          <Text color="gray.600">{orderedAt}</Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  )
                })}
              </VStack>
            </TabPanel>
            <TabPanel>
              {data.creatorDetails.bounties &&
              data.creatorDetails.bounties.length > 0 ? (
                data.creatorDetails.bounties.map((bounty) => {
                  return (
                    <VStack key={bounty.id}>
                      <Box
                        p="4"
                        borderBottom={'1px solid'}
                        borderColor="gray.400"
                        display={'flex'}
                        width="100%"
                      >
                        <Box flexShrink={0}>
                          {bounty.tikTokVideo ? (
                            <Link
                              isExternal={false}
                              to={`/content-library/video/${bounty.id}`}
                            >
                              <Image
                                height="70px"
                                width="40px"
                                mr="4"
                                objectFit={'cover'}
                                src={`${CLOUDINARY_URL}/video_id_${bounty.tikTokVideo.videoId}.jpg`}
                              />
                            </Link>
                          ) : (
                            <Image
                              height="70px"
                              width="40px"
                              mr="4"
                              objectFit={'cover'}
                              src={b256}
                            />
                          )}
                        </Box>
                        <Box>
                          <Badge colorScheme={'purple'}>{bounty.status}</Badge>
                          <Text>
                            Bounty type:{' '}
                            {bounty.type === 'SHOPIFY_AD_HOC'
                              ? 'Ad-hoc'
                              : 'Order'}
                          </Text>
                          <Text>
                            Created: {prettyFloatingDate(bounty.createdAt)}
                          </Text>
                          <Text>{bounty.tikTokVideo?.videoDescription}</Text>
                        </Box>
                      </Box>
                    </VStack>
                  )
                })
              ) : (
                <Text>
                  No bounties to show.{' '}
                  <Link
                    to={'give-gift'}
                    isExternal={false}
                    fontWeight="semibold"
                  >
                    Offer a gift
                  </Link>
                </Text>
              )}
            </TabPanel>
            <TabPanel>
              {data.creatorDetails.giftHistory &&
              data.creatorDetails.giftHistory.length > 0 ? (
                data.creatorDetails.giftHistory.map((giftHistory) => {
                  return (
                    <VStack key={giftHistory.id}>
                      <Box
                        p="4"
                        borderBottom={'1px solid'}
                        borderColor="gray.400"
                        display={'flex'}
                        width="100%"
                      >
                        <Box>
                          <Badge colorScheme={'purple'}>
                            {giftHistory.giftOfferStatus}
                          </Badge>
                          <Text>
                            Gift Name:{' '}
                            <Link
                              isExternal={false}
                              to={`/gifts/details/${giftHistory.storeGiftId}`}
                            >
                              {giftHistory.storeGiftName}
                            </Link>
                          </Text>
                          {giftHistory.giftOfferStatus === 'COMPLETED' &&
                            giftHistory.userBountyId && (
                              <Link
                                isExternal={false}
                                to={`/content-library/video/${giftHistory.userBountyId}`}
                              >
                                View Bounty
                              </Link>
                            )}
                          <Text>
                            Created: {prettyFloatingDate(giftHistory.createdAt)}
                          </Text>
                        </Box>
                      </Box>
                    </VStack>
                  )
                })
              ) : (
                <Text>
                  No gift history to show.{' '}
                  <Link
                    to={'give-gift'}
                    isExternal={false}
                    fontWeight="semibold"
                  >
                    Offer a gift
                  </Link>
                </Text>
              )}
            </TabPanel>
            <TabPanel>
              <Heading size="md">Block Creator</Heading>
              <Text mb="4">
                Blocking a creator prevents them from creating content for your
                brand and notifies Bounty.
              </Text>
              <BanCreatorButton
                userId={data.creatorDetails.id}
                storeBountyLimit={data.currentStore.bountyLimitPerMonth}
                creatorStoreBountyLimit={
                  data.creatorDetails.creatorLimitForStore
                }
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </DrawerBody>
    </>
  )
}
